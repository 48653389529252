* {
  font-family: "HelveticaNeue", "Helvetica Neue", Arial, sans-serif  !important;
  /* Add !important to overwrite all elements */
}

.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0px !important;
}

.btn.btn-yellow, .btn.btn-primary {
  color: #fff !important;
  font-weight: bold !important;
}

.material-icons{
  font-family: 'Material Icons' !important;
}